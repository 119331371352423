import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { me, userList, userDetails } from "../api/apiService";
import { ToastSuccess, ToastError } from "../components/toast-notification";

const socketIO = require("socket.io-client");

const socket = socketIO.connect(process.env.REACT_APP_SOCKET_URL, {
  transports: ["websocket"],
  auth: {
    token: localStorage.getItem("x-device-access-token"),
    deviceId: localStorage.getItem("x-device-service-id"),
  },
});

function Chat() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const UserId = queryParameters.get("userId");

  const [messages, setMessages] = useState([]);
  const [connectionStatus, setConnectionStatus] = useState(false);
  const [activeUserList, setActiveUserList] = useState([]);
  const [chatUserDetails, setChatUserDetails] = useState(null);
  const [chatText, setChatText] = useState("");

  
  const fetchProfileData = async () => {
    const myDetails = await me();

    if (UserId) {
      userDetails(UserId)
        .then(function (response) {
          setChatUserDetails(response?.data?.data);
          if (!response?.data?.data) {
            window.location.href = `/chat`;
          }
        })
        .catch(function (error) {
          ToastError(error?.response?.data?.message);
          window.location.href = `/chat`;
        });
    } else {
      userList()
        .then(function (response) {
          setActiveUserList(
            response?.data?.data?.filter(
              (m) => m._id !== myDetails?.data?.data._id,
            ),
          );
          for(const u of response?.data?.data){
            console.log(`online_status_update_${u._id}`);
            
            socket.on(`online_status_update_${u._id}`, (msg) => {
              console.log('online_status_update', msg);
            });
          }
        })
        .catch(function (error) {
          ToastError(error?.response?.data?.message);
        });
    }
  };

  useEffect(() => {
    fetchProfileData();
    init();
  }, []);

  const init = () => {
    let myId = "";
    socket.on("connect", () => {
      console.log("connect", socket.id);
      setConnectionStatus(true);
      myId = socket.id;
    });

    socket.on("connect_error", (err) => {
      console.log(err.message);
      // alert('Connection Error: ' + err.message);
      setConnectionStatus(false);
    });

    socket.on('error', (error) => {
      console.error('Socket Error:', error.message);
  });

    socket.on("disconnect", () => {
      console.log("disconnect");
      setConnectionStatus(false);
    });
   
    socket.on("error_send", (msg) => {
      console.log(msg);
    });

    socket.on("DIRECT_MESSAGE", (msg) => {
      const data = JSON.parse(msg);
      setMessages(prevItems => [...prevItems, data]);
      socket.emit("received_feedback", data);
    });

    socket.on("delivery_status", (msg) => {
      const data = JSON.parse(msg);
      console.log(data);
    });
  };

  // init();

  const onSelectChatUser = (user) => {
    window.location.href = `/chat?userId=${user._id}`;
  };

  const statusTest = () => {
    if (connectionStatus) {
      return (
        <div className="status">
          <div className="status-dot"></div>
          Online
        </div>
      );
    } else {
      return <div className="status">Offline</div>;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setChatText(value);
  };

  const onSendChat = () => {
    if(!chatText) return;

    const msgContent = {
      contentText: chatText,
      // file: {},
      codeSnippet: undefined
  };

    const msgData = {
      msgType: "DIRECT_MESSAGE",
      fromUserId: UserId,
      toUserId: chatUserDetails._id,
      dateTime: new Date(),
      content: msgContent
    }
    socket.emit("DIRECT_MESSAGE", msgData);
    setMessages(prevItems => [...prevItems, msgData]);
    setChatText('');
  };

  return !chatUserDetails ? (
    <div className="chat-body">
      <div class="user-list-container">
        <div class="user-list-header">Recent Chats</div>
        <ul class="user-list">
          {activeUserList.map((m) => (
            <li onClick={() => onSelectChatUser(m)} class="user-list-item">
              <div class="user-avatar">
                <img src={m.profileImage} alt="User" />
              </div>
              <div class="user-info">
                <div class="user-name">{m.fullName}</div>
                <div class="user-message">{m.email}</div>
              </div>
              <div class="user-status">
                <div class="status-dot"></div>
                <div class="status-text">Online</div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  ) : (
    <div className="chat-body">
      <div className="chat-container">
        <div className="chat-header">
          <div class="user-avatar">
            <img src={chatUserDetails.profileImage} alt="User" />
          </div>
          <div> {chatUserDetails.fullName}</div>
          {statusTest()}
        </div>
        <div className="chat-messages">
          {messages.map((m) => m.toUserId === UserId ? 
          <div className="message sent">{m.content.contentText}</div> 
          : <div className="message">{m.content.contentText}</div>
          
          )}
          
        </div>
        <div className="chat-input">
          <input
            type="text"
            onChange={handleChange}
            value={chatText}
            placeholder="Type a message..."
          />
          <button onClick={onSendChat}>Send</button>
        </div>
      </div>
    </div>
  );
}

export default Chat;
